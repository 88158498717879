import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span onClick={handleClickOpen}>Terms and Conditions</span>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Terms and Conditions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Terms and Conditions Updated 18th of November 2023
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <ol>
              <li>
                If a cancellation is requested, a 10% administrative charge will
                be levied on the total booking amount.
              </li>
              <li>
                Cancellations made 6 hours or less than 12 hours before the
                scheduled service will result in a 50% charge.
              </li>
              <li>
                Cancellations made less than 6 hours before the scheduled
                service will incur a 100% charge.
              </li>
              <li>
                For bookings made less than 6 hours before the scheduled
                service, an additional charge of £50 will be applied.
              </li>
              <li>Buggy services are not available at Heathrow Terminal 5.</li>
              <li>
                The Meet and Greet Service is provided for a duration of 3
                hours. Any additional hour beyond the initial 3 hours will be
                subject to an extra charge of £45 per hour.
              </li>
              <li>
                Clients are responsible for any additional costs incurred due to
                changes in the itinerary requested after the booking has been
                confirmed.
              </li>
              <li>
                Any changes to your bookings must be communicated via email at{" "}
                <Link underline="hover" href="mailto:bookings@travelserve.net">
                  bookings@travelserve.net
                </Link>
                or by calling on{" "}
                <Link underline="hover" href="tel:+4402071481900">
                  +44(0)207 148 1900
                </Link>
                .
              </li>
            </ol>
          </Typography>
          <Typography variant="body1">
            <strong>Travel Serve Limited</strong>
            <br />
            Novotel London Heathrow
            <br />
            Cherry Lane, Heathrow London, UB7 9HJ
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
