import React from "react";

// views
import Home from "../pages/Home";
import MeetGreet from "../pages/MeetGreet";
import Stripe from "components/MeetGreet/Forms/Stripe";
import { BookingConfirmation } from "components/MeetGreet/Confirmation";

const HomeRoutes = [
  { path: "/", element: <Home /> },
  { path: "/meetgreet", element: <MeetGreet /> },
  { path: "/meetgreet/stripe/:uuid", element: <Stripe /> },
  { path: "/meetgreet/confirmation/:uuid", element: <BookingConfirmation /> },
];

export default HomeRoutes;
